import * as React from 'react';

import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import EventHomepageSectionHeader from '@/components/pages/event/layout/components/home/components/event-homepage-section-header';

type SectionHeaderProps = {
    className?: string;
    title: string;
    description?: string;
    linkTitle?: React.ReactNode | string;
    linkUrl?: string;
    variant?: 'grid' | 'plain';
};

const variants = {
    grid: 'px-10 py-5',
    plain: '',
};

const SectionHeader = (props: SectionHeaderProps) => {
    const { title, description, linkTitle, linkUrl, className, variant = 'grid' } = props;

    return (
        <div className={cn('md:mt-0 md:flex md:justify-between md:items-center w-full', variants[variant], className)}>
            <div className="flex justify-start items-center gap-3">
                <div className="relative pb-5">
                    {linkUrl ? (
                        <Link
                            href={linkUrl}
                            prefetch={false}
                            rel={linkUrl.indexOf('https://') !== -1 ? 'nofollow' : undefined}
                        >
                            <EventHomepageSectionHeader title={title ?? ''} description={description} />
                        </Link>
                    ) : (
                        <EventHomepageSectionHeader title={title ?? ''} description={description} />
                    )}
                </div>
            </div>
            <div>
                {linkUrl && (
                    <Link
                        href={linkUrl}
                        prefetch={false}
                        rel={linkUrl.indexOf('https://') !== -1 ? 'nofollow' : undefined}
                        className="text-sm text-center md:text-left uppercase text-brand"
                    >
                        <>{linkTitle}</>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default SectionHeader;
