import * as React from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useLocalStorage } from '@blockworks/platform/hooks';
import { isSameDay } from '@blockworks/platform/libs/date-fns';
import { useTrack } from '@blockworks/platform/services/analytics';

import SectionHeader from '@/components/display/headers/section-header';
import EventLivestreamEmbed from '@/components/pages/event/layout/components/livestreams/components/event-livestream-embed';
import EventNewsletterGate from '@/components/pages/event/layout/components/livestreams/components/event-newsletter-gate';
import getLivestreamUrl from '@/components/pages/event/layout/components/livestreams/utils/get-livestream-url';
import getRoomById from '@/components/pages/event/layout/components/livestreams/utils/get-room-by-id';
import getRoomSessions from '@/components/pages/event/layout/components/livestreams/utils/get-room-sessions';
import UserIdentificationData from '@/components/pages/webinar/layout/type/user-identification-data';
import { Event } from '@/types/article-type';
import { AgendaDay, Room, SessionFull } from '@/types/pages/api/events/sessionize';

type StreamingType = 'livestream' | 'recording';

type EventLivestreamsProps = {
    type: StreamingType;
    event: Event;
    agenda: AgendaDay[];
};

type LivestreamDisplayProps = {
    userData: UserIdentificationData | null;
    event: Event;
    selectedRoom?: Room;
    selectedSession?: SessionFull;
    type: StreamingType;
    agendaToday: AgendaDay | undefined;
    onSubscribed: (userData: UserIdentificationData) => void;
};

const LivestreamDisplay = ({
    userData,
    event,
    selectedRoom,
    selectedSession,
    type,
    agendaToday,
    onSubscribed,
}: LivestreamDisplayProps) => {
    return (
        <div>
            <div className="flex flex-col gap-4">
                {!userData && (
                    <EventNewsletterGate onSubscribed={onSubscribed}>
                        <p className="text-sm mt-12">
                            Please enter your email address to gain access to the {event.title}{' '}
                            {type === 'livestream' ? 'livestream' : 'panel recording'}.
                            <br />
                            This email will be subscribed to our Daily Newsletter and the Blockworks Research
                            Newsletter:
                        </p>
                    </EventNewsletterGate>
                )}

                {/* If there is no room or session selected, show all available livestreams underneath each other */}
                {type === 'livestream' &&
                    userData &&
                    !selectedRoom &&
                    !selectedSession &&
                    agendaToday &&
                    getRoomSessions(agendaToday).map(room => (
                        <EventLivestreamEmbed
                            key={room.id}
                            title={room.title}
                            id={`${room.id}`}
                            session={{
                                iframeEmbedUrl: room.session.liveUrl || '',
                            }}
                        />
                    ))}

                {/* if there is a room selected, show the livestream of that room */}
                {type === 'livestream' && userData && selectedRoom && (
                    <EventLivestreamEmbed
                        title={selectedRoom.name}
                        id={`${selectedRoom.id}`}
                        session={{ iframeEmbedUrl: getLivestreamUrl(selectedRoom) }}
                    />
                )}

                {/* if there is a session selected that has a recording, show the recording of that session */}
                {type === 'recording' && userData && selectedSession && selectedSession.recordingUrl && (
                    <EventLivestreamEmbed
                        title={selectedSession.title}
                        id={`${selectedSession.id}`}
                        session={{
                            iframeEmbedUrl: selectedSession.recordingUrl,
                            title: selectedSession.title,
                            startsAt: selectedSession.startsAt,
                            endsAt: selectedSession.endsAt,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const EventLivestreams = (props: EventLivestreamsProps) => {
    const { event, agenda, type } = props;

    const track = useTrack();
    const { storedValue: userData, setValue: setUserData } = useLocalStorage<UserIdentificationData | null>(
        'user.identify',
    );
    const router = useRouter();
    const roomId: string = `${router.query.room as string}` || '';
    const sessionId: string = `${router.query.session as string}` || '';

    const selectedRoom = React.useMemo(() => getRoomById(agenda, roomId), [agenda, roomId]);
    const selectedSession = React.useMemo(() => {
        for (const day of agenda) {
            for (const room of day.rooms) {
                const session = room.sessions?.find(s => s.id === sessionId);
                if (session) {
                    return session;
                }
            }
        }
        return null;
    }, [agenda, roomId]);
    const agendaToday = React.useMemo(() => agenda.find(({ date }) => isSameDay(new Date(), new Date(date))), [agenda]);

    useEffect(() => {
        track('Livestream.Watch', {
            type,
            sessionId,
            roomId,
            eventName: event.title,
            sessionTitle: selectedSession?.title,
            roomName: selectedRoom?.name,
        });
    }, []);

    return (
        <div className="p-5 md:p-10 xl:px-20 sm:mr-16 mb-12">
            <section className="flex flex-col gap-10">
                <div>
                    <SectionHeader variant="plain" title={type} className="border-b" />

                    <LivestreamDisplay
                        event={event}
                        onSubscribed={_userData => setUserData(_userData)}
                        selectedSession={selectedSession!}
                        selectedRoom={selectedRoom!}
                        userData={userData}
                        type={type}
                        agendaToday={agendaToday}
                    />
                </div>
            </section>
        </div>
    );
};

export default EventLivestreams;
