import { useEffect, useMemo } from 'react';
import { ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { formatUnixTimestampToTime } from '@blockworks/platform/api/grip';
import { useLocalStorage } from '@blockworks/platform/hooks';
import { useTrack } from '@blockworks/platform/services/analytics';

import ChevronBackIcon from '@/assets/icons/chevron-back-icon';
import { NoResultsFound } from '@/components/common/no-results-found';
import UserIdentificationData from '@/components/pages/webinar/layout/type/user-identification-data';

import SectionHeader from '../event-section-header';
import EventNewsletterGate from '../livestreams/components/event-newsletter-gate';

type StreamingType = 'livestream' | 'recording';

const GripLivestreams = ({
    mappedSessions,
    type,
    backHref,
}: {
    mappedSessions: Record<string, any>;
    type: StreamingType;
    backHref?: string;
}) => {
    const track = useTrack();
    const { storedValue: userData, setValue: setUserData } = useLocalStorage<UserIdentificationData | null>(
        'user.identify',
    );
    const router = useRouter();
    const sessionId: string = `${router.query.sessionId as string}` || '';
    const selectedSession = useMemo(() => mappedSessions[sessionId], [mappedSessions, sessionId]);

    useEffect(() => {
        if (selectedSession) {
            track('Livestream.Watch', {
                type,
                sessionId,
                eventStage: selectedSession.session_location,
                eventName: selectedSession.name,
            });
        }
    }, [selectedSession, sessionId, track, type]);

    return (
        <div className="p-5 md:p-10 xl:px-20 sm:mr-16 mb-12">
            {!selectedSession && (
                <NoResultsFound
                    title="Something Went Wrong"
                    description="Sorry, the session you are looking for is not available."
                    className="min-h-[350px] border"
                />
            )}
            {selectedSession && (
                <section className="flex flex-col gap-10">
                    {!userData && (
                        <EventNewsletterGate onSubscribed={userData => setUserData(userData)}>
                            <p className="text-sm mt-12">
                                Please enter your email address to gain access to the {selectedSession.name}{' '}
                                {type === 'livestream' ? 'livestream' : 'panel recording'}.
                                <br />
                                This email will be subscribed to our Daily Newsletter and the Blockworks Research
                                Newsletter:
                            </p>
                        </EventNewsletterGate>
                    )}
                    {userData && (
                        <div className="relative">
                            {backHref && (
                                <div className="block md:absolute mb-2 md:mb-0 top-[6px] left-[-35px]">
                                    <Link href={backHref}>
                                        <button
                                            aria-label="Go back to agenda"
                                            className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 transition"
                                        >
                                            <ChevronBackIcon width={10} height={10} />
                                        </button>
                                    </Link>
                                </div>
                            )}
                            <SectionHeader
                                variant="plain"
                                title={`${type[0]?.toUpperCase()}${type.slice(1)}`}
                                className="border-b"
                            />

                            <div className="w-full h-full mt-12">
                                <h2 className="text-2xl flex items-center gap-2 align-middle">
                                    <MapPinIcon className="inline-block w-6 h-6" /> {selectedSession.session_location}
                                </h2>
                                <div className="flex flex-col gap-2 sm:flex-row sm:gap-3 text-slate-500 ">
                                    <h3 className="flex items-center gap-1 text-xs tracking-wide">
                                        {selectedSession.name}
                                    </h3>
                                    <div className="flex items-center gap-1 text-xs tracking-wide">
                                        <ClockIcon className="inline-block w-3.5 h-3.5" />
                                        {formatUnixTimestampToTime(
                                            Number(selectedSession.date_start),
                                            selectedSession.timezone,
                                        )}
                                        -
                                        {formatUnixTimestampToTime(
                                            Number(selectedSession.date_end),
                                            selectedSession.timezone,
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="relative w-full h-full mt-6 border-2 p-2 bg-purple-50"
                                    style={{ borderColor: 'var(--event-accent-color)' }}
                                >
                                    <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                                        <iframe
                                            className="absolute top-0 left-0 w-full h-full border-0"
                                            src={selectedSession.video_url ?? selectedSession.stream_url}
                                            title={'someselected session'}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            )}
        </div>
    );
};

export default GripLivestreams;
