import { useState } from 'react';

import { AgendaSpeaker, isInUnixTimeRange, Timezone, TransformedAgendaSchedule } from '@blockworks/platform/api/grip';
import { isSameDay, toZonedTime } from '@blockworks/platform/libs/date-fns';

import { Event } from '@/types/article-type';

import AgendaTabs from './components/agenda-tabs';
import SessionCard from './components/session-card';
import SessionPopup from './components/session-popup';
import SpeakerPopup from './components/speaker-popup';
import StageSection from './components/stage-section';

interface GripAgendaProps {
    event: Event;
    agenda: TransformedAgendaSchedule[];
}

const PMLS4_TIMEZONE: Timezone = 'America/New_York';

const GripAgendaLayout = ({ event, agenda }: GripAgendaProps) => {
    const [isSessionPopupOpen, setIsSessionPopupOpen] = useState(false);
    const [sessionPopupSpeakers, setSessionPopupSpeakers] = useState<AgendaSpeaker[]>([]);
    const [sessionPopupStartsAt, setSessionPopupStartsAt] = useState<number>(0);
    const [sessionPopupEndsAt, setSessionPopupEndsAt] = useState<number>(0);
    const [sessionPopupTitle, setSessionPopupTitle] = useState<string>('');
    const [sessionPopupStageName, setSessionPopupStageName] = useState<string>('');
    const [timezone, setTimezone] = useState<string>(PMLS4_TIMEZONE);
    const [isSpeakerPopupOpen, setIsSpeakerPopupOpen] = useState(false);
    const [popupSpeaker, setPopupSpeaker] = useState<AgendaSpeaker | null>(null);
    const [sessionId, setSessionId] = useState<string>('');
    const [streamUrl, setStreamUrl] = useState<string | null>(null);
    const [recordingUrl, setRecordingUrl] = useState<string | null>(null);

    const gripOptions = agenda.map(item => item.date);
    const [gripTab, setGripTab] = useState<string>(() => {
        const todaysAgenda = gripOptions.find(date => {
            const todayUTC = toZonedTime(new Date(), 'UTC');
            const dateUTC = toZonedTime(new Date(`${date}T00:00:00Z`), 'UTC');

            return isSameDay(todayUTC, dateUTC);
        })!;

        return todaysAgenda ?? gripOptions[0];
    });

    const onSelectSession = ({
        speakers,
        title,
        startsAt,
        endsAt,
        timezoneInput,
        stageName,
        sessionId,
        streamUrl,
        recordingUrl,
    }: {
        speakers: AgendaSpeaker[];
        selectedSpeaker: number | null;
        title: string;
        timezoneInput: string;
        startsAt: number;
        endsAt: number;
        stageName: string;
        sessionId: string;
        streamUrl: string | null;
        recordingUrl: string | null;
    }) => {
        setSessionPopupSpeakers(speakers);
        setSessionPopupStartsAt(startsAt);
        setSessionPopupEndsAt(endsAt);
        setSessionPopupTitle(title);
        setTimezone(timezoneInput);
        setSessionPopupStageName(stageName);
        setIsSessionPopupOpen(true);
        setSessionId(sessionId);
        setStreamUrl(streamUrl);
        setRecordingUrl(recordingUrl);
    };

    const onSpeakerClick = (speaker: AgendaSpeaker) => {
        setPopupSpeaker(speaker);
        setIsSpeakerPopupOpen(true);
    };

    return (
        <div className="flex flex-col gap-4 p-5 md:p-10 xl:pl-20 md:pr-32 min-h-96">
            <SessionPopup
                event={event}
                isOpen={isSessionPopupOpen}
                onClose={() => setIsSessionPopupOpen(false)}
                startsAt={sessionPopupStartsAt}
                endsAt={sessionPopupEndsAt}
                speakers={sessionPopupSpeakers}
                title={sessionPopupTitle}
                timezone={timezone}
                stageName={sessionPopupStageName}
                sessionId={sessionId}
                streamUrl={streamUrl}
                recordingUrl={recordingUrl}
            />
            {popupSpeaker && (
                <SpeakerPopup
                    isRoot
                    speaker={popupSpeaker}
                    event={event}
                    isOpen={isSpeakerPopupOpen}
                    onClose={() => {
                        setIsSpeakerPopupOpen(false);
                        setPopupSpeaker(null);
                    }}
                />
            )}

            <div className="flex">
                <h2 className="flex-auto text-lg tracking-wide uppercase font-bold">Agenda</h2>
            </div>
            <AgendaTabs onSelect={setGripTab} selected={gripTab} options={gripOptions} />
            {agenda.length > 0 &&
                agenda.map((day, index: number) => {
                    return (
                        <div
                            className="grid lg:auto-cols-max lg:grid-flow-col overflow-x-scroll border max-w-full w-full lg:w-max"
                            key={`${index}-${day.date}`}
                        >
                            {gripTab === day.date
                                ? day.stages.map((stage, index) => {
                                      const activeStage = stage?.events.find(event => {
                                          return isInUnixTimeRange({
                                              unixTimetoCheckInSeconds: Date.now() / 1000,
                                              unixTimeStart: event.date_start,
                                              unixTimeEnd: event.date_end,
                                              timezone: PMLS4_TIMEZONE,
                                          });
                                      });

                                      const activeStageHref = activeStage
                                          ? `/event/${event.slug}/livestreams?sessionId=${activeStage.session_id}`
                                          : undefined;

                                      return (
                                          <StageSection
                                              key={`${index}-${stage.stage}`}
                                              name={stage.stage}
                                              activeStageHref={activeStageHref}
                                          >
                                              {stage.events.map(event => {
                                                  return (
                                                      <SessionCard
                                                          key={event.session_id}
                                                          onSelectSession={onSelectSession}
                                                          onSpeakerClick={onSpeakerClick}
                                                          startsAt={event.date_start}
                                                          endsAt={event.date_end}
                                                          title={event.name}
                                                          stageName={event.session_location}
                                                          speakers={event.speakers}
                                                          timezone={event.timezone}
                                                          sessionId={String(event.session_id)}
                                                          streamUrl={event.stream_url}
                                                          recordingUrl={event.video_url}
                                                      />
                                                  );
                                              })}
                                          </StageSection>
                                      );
                                  })
                                : null}
                        </div>
                    );
                })}
        </div>
    );
};

export default GripAgendaLayout;
