import { createMutation } from 'react-query-kit';

import { wpApi } from './wp';

const submitBecomeASponsor = createMutation({
    mutationFn: wpApi.post.submitBecomeASponsor,
    mutationKey: [wpApi.post.submitBecomeASponsor.cachePrefix],
});

const submitCallForSpeakers = createMutation({
    mutationFn: wpApi.post.submitCallForSpeakers,
    mutationKey: [wpApi.post.submitCallForSpeakers.cachePrefix],
});

const wpMutations = {
    submitBecomeASponsor: { use: submitBecomeASponsor },
    submitCallForSpeakers: { use: submitCallForSpeakers },
};

export { wpMutations };
