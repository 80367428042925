import { useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash-es';
import { useRouter } from 'next/router';

import { Session, TransformedAgendaSchedule } from '@blockworks/platform/api/grip';
import { slugify } from '@blockworks/platform/utils';
import { Spinner } from '@blockworks/ui/spinner';

import { NoResultsFound } from '@/components/common/no-results-found';
import { withExceptionCatcher } from '@/components/hoc/with-exception-catcher';
import AgendaLayout from '@/components/pages/event/layout/components/agenda/agenda-layout';
import ContactUsLayout from '@/components/pages/event/layout/components/contact-us/contact-us-layout';
import DynamicPage from '@/components/pages/event/layout/components/dynamic-page/dynamic-page';
import FaqsLayout from '@/components/pages/event/layout/components/faqs/faqs-layout';
import HomeLayout from '@/components/pages/event/layout/components/home/home-layout';
import EventTabHotelBooking from '@/components/pages/event/layout/components/hotel-booking/event-tab-hotel-booking';
import EventLivestreams from '@/components/pages/event/layout/components/livestreams/event-livestreams';
import ParticipatingCompaniesLayout from '@/components/pages/event/layout/components/participating-companies/participating-companies-layout';
import SessionizeAgendaLayout from '@/components/pages/event/layout/components/sessions/sessionize-agenda-layout';
import SpeakersLayout from '@/components/pages/event/layout/components/speakers/speakers-layout';
import SponsorsLayout from '@/components/pages/event/layout/components/sponsors/sponsors-layout';
import TicketsLayout from '@/components/pages/event/layout/components/tickets/tickets-layout';
import EventPageLayout from '@/components/pages/event/layout/event-page-layout';
import getEventsContentSection from '@/components/pages/event/utils/get-events-content-section';
import { Event } from '@/types/article-type';
import { EventSpeaker } from '@/types/event-speaker';
import { HotelsContent, ParticipatingCompaniesContent, PricingContent } from '@/types/events-custom-fields';
import { AgendaDay, SpeakerFull } from '@/types/pages/api/events/sessionize';
import fetchEventSpeakers from '@/utils/functions/wordpress/fetch-event-speakers';
import replaceCmsUrl from '@/utils/functions/wordpress/replace-cms-url';

import GripAgendaLayout from './layout/components/grip-agenda/grip-agenda-layout';
import GripLivestreams from './layout/components/grip-livestreams/grip-livestreams';
export interface EventPageControllerProps {
    event: Event;
    speakers: EventSpeaker[];
    agenda: TransformedAgendaSchedule[];
    sessionize: AgendaDay[];
    sessionizeSpeakers: SpeakerFull[];
}

export const EventPageController = ({
    event,
    speakers,
    agenda,
    sessionize,
    sessionizeSpeakers,
}: EventPageControllerProps) => {
    const router = useRouter();
    const [speakersData, setSpeakersData] = useState<EventSpeaker[]>(speakers || []);
    const isSessionize = !!event?.customFields.sessionize_id;
    const isGrip = !!event?.customFields.grip_api_id;
    const showSessionizeAgenda = event?.customFields.sessionize_agenda_active;
    const { tab, previewAgenda } = router.query;

    useEffect(() => {
        if (!event?.id) {
            return;
        }
        if (speakersData.length === 0) {
            // Re-fetch speakers
            fetchEventSpeakers(event.id)
                .then(result => {
                    if (result) {
                        setSpeakersData(result);
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [event, speakersData.length]);

    // Fetch event data
    const [pricing, participatingCompanies, sponsorsAll, faqs, hotelsContent] = useMemo(
        () => [
            getEventsContentSection<PricingContent>(event?.customFields?.content || [], 'pricing'),
            getEventsContentSection<ParticipatingCompaniesContent>(
                event?.customFields?.content || [],
                'participating_companies',
            ),
            getEventsContentSection(event?.customFields?.content || [], 'founding_sponsors') || [],
            getEventsContentSection(event?.customFields?.content || [], 'faq'),
            getEventsContentSection<HotelsContent>(event?.customFields?.content || [], 'hotels'),
        ],
        [event?.customFields?.content],
    );

    if (!event) {
        return (
            <div className="w-full p-40 flex justify-center">
                <div className="align-middle">
                    <Spinner size={10} />
                </div>
            </div>
        );
    }

    const pages = event.customFields?.pages || [];
    const dynamicTabs = pages.map(page => ({
        title: page.title,
        slug: slugify(page.title),
    }));

    const tabLayoutSelector = (selectedTab?: string | null) => {
        switch (selectedTab) {
            case 'tickets':
                return <TicketsLayout pricing={pricing} event={event} />;
            case 'agenda':
                if (isGrip) {
                    return <GripAgendaLayout event={event} agenda={agenda} />;
                }
                if (isSessionize) {
                    return (
                        <SessionizeAgendaLayout
                            event={event}
                            agenda={sessionize}
                            speakers={speakersData}
                            additionalSpeakers={sessionizeSpeakers}
                        />
                    );
                }
                return <AgendaLayout event={event} agenda={agenda} />;
            case 'speakers':
                return <SpeakersLayout event={event} speakers={speakers} />;
            case 'participating-companies':
                return <ParticipatingCompaniesLayout participatingCompanies={participatingCompanies} event={event} />;
            case 'sponsors':
                return <SponsorsLayout event={event} sponsors={sponsorsAll} />;
            case 'contact-us':
                return <ContactUsLayout event={event} />;
            case 'faqs':
                return <FaqsLayout faqs={faqs} />;
            case 'livestreams':
                if (isGrip) {
                    const mappedSessions = keyBy(
                        agenda.reduce((acc, curr) => {
                            const eventsInStages = curr.stages
                                .map(stage => {
                                    return stage.events;
                                })
                                .flat();

                            acc.push(...eventsInStages);
                            return acc;
                        }, [] as Session[]),
                        event => event.session_id,
                    );

                    const backHref = event.slug ? `/event/${event.slug}/agenda` : undefined;
                    return <GripLivestreams mappedSessions={mappedSessions} type={'livestream'} backHref={backHref} />;
                }

                // deprecated. uses old sessionize api
                if (isSessionize) {
                    return <EventLivestreams type="livestream" event={event} agenda={sessionize} />;
                }
                return (
                    <NoResultsFound
                        title="Something Went Wrong"
                        description="Sorry, the livestream you are looking for is not available."
                        className="min-h-[350px]"
                    />
                );
            case 'recordings':
                if (isGrip) {
                    const mappedSessions = keyBy(
                        agenda.reduce((acc, curr) => {
                            const eventsInStages = curr.stages
                                .map(stage => {
                                    return stage.events;
                                })
                                .flat();

                            acc.push(...eventsInStages);
                            return acc;
                        }, [] as Session[]),
                        event => event.session_id,
                    );

                    return <GripLivestreams mappedSessions={mappedSessions} type={'recording'} />;
                }

                // deprecated. uses old sessionize api
                if (isSessionize) {
                    return <EventLivestreams type="recording" event={event} agenda={sessionize} />;
                }

                return (
                    <NoResultsFound
                        title="Something Went Wrong"
                        description="Sorry, the recording you are looking for is not available."
                        className="min-h-[350px]"
                    />
                );
            case 'book-hotel':
                return <EventTabHotelBooking event={event} hotelsContent={hotelsContent} content={''} />;
            default:
                // eslint-disable-next-line no-case-declarations
                const dynamicPage = pages.find(page => slugify(page.title) === selectedTab);
                if (dynamicPage) {
                    return <DynamicPage content={replaceCmsUrl(dynamicPage.content)} />;
                }

                return <HomeLayout event={event} speakers={speakers} />;
        }
    };

    // Hide speakers and agenda if there is none
    const hideTabs = [];

    if (speakers.length === 0) {
        hideTabs.push('speakers');
    }
    if (isSessionize ? sessionize.length === 0 || !showSessionizeAgenda : agenda.length === 0) {
        hideTabs.push('agenda');
    }
    if (pricing.length === 0) {
        hideTabs.push('tickets');
    }
    if (sponsorsAll.length === 0) {
        hideTabs.push('sponsors');
    }
    if (!faqs || !faqs?.faq_category) {
        hideTabs.push('faqs');
    }
    if (!participatingCompanies) {
        hideTabs.push('participating-companies');
    }
    if (!hotelsContent) {
        hideTabs.push('book-hotel');
    }

    const hideAgenda = tab === 'agenda' && isSessionize && !showSessionizeAgenda && !previewAgenda;
    const activeTab = hideAgenda ? 'home' : tab;

    return (
        <EventPageLayout
            dynamicTabs={dynamicTabs}
            event={event}
            hideTabs={hideTabs}
            accentColor={event.customFields.accent_color}
        >
            {tabLayoutSelector(activeTab as string | null)}
        </EventPageLayout>
    );
};

export default withExceptionCatcher(EventPageController);
