import { defineEndpoints } from '../config/define-endpoints';

import { GetBrandRequest, GetBrandResponse } from './models/brand.models';
import { GetCategoriesRequest, GetCategoriesResponse } from './models/categories.models';
import {
    Event,
    GetEventByIdRequest,
    GetEventByIdResponse,
    GetEventSpeakearsByIdRequest,
    GetEventSpeakearsByIdResponse,
    GetEventsRequest,
    GetEventsResponse,
} from './models/event.models';
import { GetPostsByCategoryRequest, GetPostsByCategoryResponse, Post as WordpressPost } from './models/posts.models';
import { CompaniesEntity } from './models/shared.models';

export interface BecomeASponsorInputs {
    name: string;
    email: string;
    company: string;
    roleTitle: string;
    calendlyCalendarLink?: string;
    anythingElseWeShouldKnow?: string;
    sponsorFormId: number;
}

export interface CallForSpeakersInputs {
    email: string;
    formId: string;
}

const WP_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        BRAND: template('/wp/v2/brand'),
        EVENTS: template('/wp/v2/events'),
        EVENT_BY_ID: template('/b-headless/v1/events/{id}'),
        EVENT_SPEAKERS: template('/b-headless/v1/events/{id}/speakers'),
        CATEGORIES: template('/wp/v2/categories'),
        POSTS_BY_CATEGORY: template('/wp/v2/posts'),
    },
    POST: {
        SUBMIT_GRAVITY_FORM: template('/gf/v2/forms/{id}/submissions'),
    },
}));

export type {
    CompaniesEntity,
    Event,
    GetBrandRequest,
    GetBrandResponse,
    GetCategoriesRequest,
    GetCategoriesResponse,
    GetEventByIdRequest,
    GetEventByIdResponse,
    GetEventSpeakearsByIdRequest,
    GetEventSpeakearsByIdResponse,
    GetEventsRequest,
    GetEventsResponse,
    GetPostsByCategoryRequest,
    GetPostsByCategoryResponse,
    WordpressPost,
};
export { WP_ENDPOINTS };
