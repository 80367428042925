import * as React from 'react';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';

import EventSectionWrapper from '@/components/pages/event/layout/components/event-section-wrapper';
import Countdown from '@/components/pages/event/layout/components/home/components/countdown';
import RenderEventContent from '@/components/pages/event/layout/components/home/components/renders/render-event-content';
import SectionFollow from '@/components/pages/event/layout/components/home/section-follow';
import type { TabLayoutProps } from '@/components/pages/event/types/tab-layout';
import QuoteSection from '@/components/sections/quote-section';
import EditorialConfig from '@/editorial-config';
import convertTimezone from '@/utils/functions/time/convert-timezone';

const formatTaglineColor = (tagline: string) => {
    const formattedText = tagline.replace(/{([^}]*)}/g, '<span class="text-event-accent">$1</span>');

    return <RenderSimpleHtml html={formattedText} />;
};

const HomeLayout = (props: TabLayoutProps) => {
    const { event, speakers: passedSpeakers } = props;

    const countdownDate = event.customFields?.countdown_date
        ? new Date(`${event.customFields?.countdown_date} EDT`)
        : null;
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <div className="w-full">
            {event.customFields?.tagline && (
                <EventSectionWrapper>
                    <div className="w-full md:px-32 lg:px-64 py-10 text-4xl text-center font-thin italic">
                        {formatTaglineColor(event.customFields?.tagline)}
                    </div>
                </EventSectionWrapper>
            )}
            {countdownDate && countdownDate > convertTimezone(new Date(), EditorialConfig.timezone) && (
                <div className="text-event-accent">
                    <Countdown
                        text={event.customFields?.countdown_text}
                        date={convertTimezone(countdownDate, currentTimezone)}
                        className="flex flex-col xl:flex-row xl:align-middle gap-3 lg:gap-5 bg-white p-5 md:p-10 xl:px-20 md:pr-32 xl:pr-32"
                    />
                </div>
            )}
            {event.content && (
                <EventSectionWrapper>
                    <QuoteSection title={event.customFields.home_info || ''} label="" className="bg-[color:#f7f7f7]">
                        <div
                            itemProp="description"
                            className="md:pr-12 text-md lg:text-xl text-left text-[#110b29] prose max-w-none prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-purple-500 prose-a:font-base prose-a:border-none prose-a:hover:border-none prose-a:underline prose-pre:rounded-xl prose-pre:bg-purple-900 prose-pre:shadow-lg"
                        >
                            <div className="flex flex-col lg:flex-row lg:space-x-8">
                                <RenderSimpleHtml html={event.content || ''} className="grow" />
                                {event.contentGifUrl && (
                                    <div className="p-1 max-w-xs lg:max-w-sm">
                                        <img src={event.contentGifUrl} alt={event.title} className="w-full -mt-4" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </QuoteSection>
                </EventSectionWrapper>
            )}
            <RenderEventContent event={event} speakers={passedSpeakers ?? []} />
            <SectionFollow>
                <p>
                    For the latest news and updates about {event.title}, follow{' '}
                    <a
                        className="font-bold underline"
                        href={`https://twitter.com/@${event.customFields?.twitter_handle || 'Blockworks_'}`}
                        rel="nofollow"
                    >
                        @{event.customFields?.twitter_handle || 'Blockworks_'}
                    </a>
                    .
                </p>
            </SectionFollow>
        </div>
    );
};

export default HomeLayout;
