import { Fragment, useState } from 'react';
import * as React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ClockIcon, MapPinIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';

import { AgendaSpeaker, formatUnixTimestampToTime, isInUnixTimeRange, Timezone } from '@blockworks/platform/api/grip';
import { cn } from '@blockworks/ui/utils';

import { Event } from '@/types/article-type';

import SpeakerPopup from './speaker-popup';

interface SessionPopupProps {
    isOpen: boolean;
    event: Event;
    onClose: () => void;
    startsAt: number;
    endsAt: number;
    speakers: AgendaSpeaker[];
    title: string;
    stageName: string;
    timezone: string;
    sessionId: string;
    streamUrl: string | null;
    recordingUrl: string | null;
}

const SessionPopup = ({
    event,
    isOpen,
    onClose,
    startsAt,
    endsAt,
    speakers,
    title,
    timezone,
    stageName,
    streamUrl,
    recordingUrl,
    sessionId,
}: SessionPopupProps) => {
    const [activeSpeaker, setActiveSpeaker] = useState<AgendaSpeaker>();
    const start = formatUnixTimestampToTime(startsAt, timezone);
    const end = formatUnixTimestampToTime(endsAt, timezone);

    const isLive = isInUnixTimeRange({
        unixTimetoCheckInSeconds: Date.now() / 1000,
        unixTimeStart: startsAt,
        unixTimeEnd: endsAt,
        timezone: timezone as Timezone,
    });

    return (
        <Transition.Root show={isOpen}>
            <Dialog
                open={isOpen}
                onClose={onClose}
                className="relative z-50 h-full w-full"
                style={{ '--event-accent-color': event.customFields.accent_color } as any}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto w-full">
                    <div className="flex min-h-full items-center justify-center p-4 w-full">
                        <Dialog.Panel className="flex gap-2 flex-col relative mx-auto rounded bg-white py-6 px-8 w-[640px] min-h-[240px]">
                            <div className="absolute top-3 right-3 flex gap-1">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="inline-flex p-1.5 text-event-accent hover:bg-purple-50 focus-visible:outline-event-accent"
                                >
                                    <span className="sr-only">Close Session Information Popup</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>

                            <Dialog.Title className="text-2xl flex gap-2 align-middle">
                                <span>{title}</span>
                                {isLive && streamUrl && (
                                    <Link
                                        href={`/event/${event.slug}/livestreams?sessionId=${sessionId}`}
                                        className="rounded-none bg-white h-fit border border-event-accent text-sm px-1.5 py-0.5 mr-6"
                                    >
                                        <span className="text-event-accent whitespace-nowrap">Watch Livestream</span>
                                    </Link>
                                )}
                                {recordingUrl && (
                                    <Link
                                        href={`/event/${event.slug}/recordings?sessionId=${sessionId}`}
                                        className="rounded-none bg-white h-fit border border-event-accent text-sm px-1.5 py-0.5 mr-6"
                                    >
                                        <span className="text-event-accent whitespace-nowrap">Watch Recording</span>
                                    </Link>
                                )}
                            </Dialog.Title>
                            <div className="flex flex-col gap-2 sm:flex-row sm:gap-3 text-slate-500 ">
                                <div className="flex items-center gap-1 text-xs tracking-wide">
                                    <MapPinIcon className="inline-block w-3.5 h-3.5" /> {stageName}
                                </div>
                                <div className="flex items-center gap-1 text-xs tracking-wide">
                                    <ClockIcon className="inline-block w-3.5 h-3.5" /> {start} - {end}
                                </div>
                            </div>

                            {speakers?.length > 0 && (
                                <div className="flex flex-col gap-3">
                                    <h3 className="text-xl text-headline border-bottom">Speakers</h3>
                                    <div className="text-slate-500 text-lg leading-relaxed grid grid-cols-2 lg:grid-cols-3 gap-4">
                                        {speakers.map(speaker => (
                                            <button
                                                key={speaker.name}
                                                className={cn(
                                                    'relative border pt-8 pb-4 px-2 gap-[0.125rem] inline-flex flex-col items-center focus-visible:outline-event-accent',
                                                    speaker.isModerator ? 'border-event-accent' : '',
                                                )}
                                                onClick={() => setActiveSpeaker(speaker)}
                                            >
                                                <Image
                                                    className="relative mb-2 inline-block w-[80%] rounded-full object-cover aspect-1"
                                                    src={speaker.thumbnail}
                                                    alt="speaker"
                                                    width={96}
                                                    height={96}
                                                />
                                                <h3 className="text-sm leading-5 font-bold text-black">
                                                    {speaker.name}
                                                </h3>
                                                {speaker.isModerator && (
                                                    <span className="absolute top-0 right-0 px-2 leading-5 flex rounded-bl-md bg-event-accent text-light text-xxs">
                                                        Moderator
                                                    </span>
                                                )}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Dialog.Panel>
                    </div>
                </div>
                {activeSpeaker && (
                    <SpeakerPopup
                        speaker={activeSpeaker}
                        event={event}
                        isOpen
                        onClose={() => setActiveSpeaker(undefined)}
                    />
                )}
            </Dialog>
        </Transition.Root>
    );
};

export default SessionPopup;
