import { isAfter, isBefore, toZonedTime } from '@blockworks/platform/libs/date-fns';

// This is not a exlusive list of what we support. Feel free
// to add more as needed
export type Timezone =
    | 'UTC'
    | 'America/New_York'
    | 'America/Denver'
    | 'America/Los_Angeles'
    | 'America/Chicago'
    | 'Europe/London'
    | 'Europe/Paris'
    | 'Asia/Tokyo'
    | 'Asia/Shanghai';

const timezones = [
    'UTC',
    'America/New_York',
    'America/Denver',
    'America/Los_Angeles',
    'America/Chicago',
    'Europe/London',
    'Europe/Paris',
    'Asia/Tokyo',
    'Asia/Shanghai',
] as const;

export const isTimezone = (value: string): value is Timezone => (timezones as readonly string[]).includes(value);

export const isInUnixTimeRange = ({
    unixTimetoCheckInSeconds,
    unixTimeStart,
    unixTimeEnd,
    timezone,
}: {
    unixTimetoCheckInSeconds: number; // expects ms
    unixTimeStart: number;
    unixTimeEnd: number;
    timezone: Timezone;
}): boolean => {
    if (!timezone) {
        return false;
    }

    const toCheckTime = toZonedTime(unixTimetoCheckInSeconds, timezone);
    const startZonedTime = toZonedTime(unixTimeStart, timezone);
    const endZonedTime = toZonedTime(unixTimeEnd, timezone);

    return isAfter(toCheckTime, startZonedTime) && isBefore(toCheckTime, endZonedTime);
};
