import * as React from 'react';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { cn } from '@blockworks/ui/utils';

import EventSectionHeader from '@/components/pages/event/layout/components/event-section-header';
import { SponsorGroup } from '@/types/events-custom-fields';

type SectionSponsorsProps = {
    className?: string;
    sponsorGroup: SponsorGroup[];
};

const SectionSponsors = (props: SectionSponsorsProps) => {
    const { className, sponsorGroup } = props;

    const sponsorGroupConfig = [
        {
            className: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10',
            width: 375,
            height: 180,
        },
        {
            className: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8',
            width: 275,
            height: 170,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6',
            width: 215,
            height: 144,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
    ];

    return (
        <div className={cn('flex flex-col gap-10', className)}>
            {sponsorGroup.map((sponsor_group, sgIndex) => (
                <section key={sgIndex} className="flex flex-col items-center justify-center gap-3">
                    <EventSectionHeader variant="plain" title={sponsor_group.title || 'Sponsors'} />
                    <div
                        className={cn(
                            'grid w-full',
                            sponsorGroupConfig[sgIndex]?.className ||
                                'grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10',
                        )}
                    >
                        {sponsor_group.founding_sponsors &&
                            sponsor_group.founding_sponsors.map((sponsor, sIndex) => (
                                <div
                                    key={sIndex}
                                    className="flex flex-col justify-center items-center align-center flex-grow gap-5 p-10 bg-white border overflow-hidden"
                                    style={{
                                        height: `${sponsorGroupConfig[sgIndex]?.height}px`,
                                    }}
                                >
                                    <ImageWithFallback
                                        src={sponsor.logo || ''}
                                        width={sponsorGroupConfig[sgIndex]?.width || 300}
                                        height={sponsorGroupConfig[sgIndex]?.height || 125}
                                        alt=""
                                        className="relative p-10 md:p-0 object-fit"
                                    />
                                </div>
                            ))}
                    </div>
                </section>
            ))}
        </div>
    );
};

export default SectionSponsors;
