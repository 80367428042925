import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';

import { AgendaSpeaker } from '@blockworks/platform/api/grip';
import { RenderHtml } from '@blockworks/ui/legacy/string';

import { Event } from '@/types/article-type';

interface SpeakerPopupProps {
    isOpen: boolean;
    event: Event;
    speaker: AgendaSpeaker;
    onClose: () => void;
    isRoot?: boolean;
}

const SpeakerPopup = ({ event, speaker, isOpen, onClose, isRoot = false }: SpeakerPopupProps) => {
    const TransitionComp = Transition[isRoot ? 'Root' : 'Child'];
    return (
        <TransitionComp show={isOpen} as={Fragment}>
            <Dialog
                open={isOpen}
                onClose={onClose}
                className="relative z-50 h-full w-full"
                style={{ '--event-accent-color': event.customFields.accent_color } as any}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto w-full">
                    <div className="flex min-h-full items-center justify-center p-4 w-full">
                        <Dialog.Panel className="flex gap-2 flex-col relative mx-auto rounded bg-white pt-6 p-8 w-[640px]">
                            <div className="absolute top-3 right-3 flex gap-1">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="inline-flex p-1.5 text-event-accent hover:bg-purple-50 focus-visible:outline-event-accent"
                                >
                                    <span className="sr-only">Close Session Information Popup</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>

                            <div className="grid grid-cols-[96px_1fr] gap-x-6 gap-y-3">
                                <Image
                                    className="relative inline-block rounded-full aspect-1 object-cover"
                                    src={speaker.thumbnail}
                                    alt="speaker"
                                    width={96}
                                    height={96}
                                />
                                <div className="flex flex-col flex-auto justify-center">
                                    <Dialog.Title className="text-2xl lg:text-3xl">{speaker.name}</Dialog.Title>
                                    {speaker.company && <p className="text-md text-slate-500">{speaker.company}</p>}
                                    {speaker.title && <p className="text-sm text-slate-500">{speaker.title}</p>}
                                </div>
                                <div className="col-span-2">
                                    {speaker.description && (
                                        <RenderHtml className="prose mb-2 last:mb-0 px-1" html={speaker.description} />
                                    )}
                                </div>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </TransitionComp>
    );
};

export default SpeakerPopup;
