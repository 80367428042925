import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { Link } from '@blockworks/ui/legacy/link';
import { Modal } from '@blockworks/ui/modal';
import { cn } from '@blockworks/ui/utils';

import BecomeASponsorForm from '@/components/form/become-a-sponsor-form';
import MainMenuButton from '@/components/pages/event/layout/components/main-menu-button';
import { Link as EventCustomFieldLink } from '@/types/events-custom-fields';
import useAnalyticsTrigger from '@/utils/hooks/use-analytics-trigger';

import Menu from './menu';

type EventMainMenuProps = {
    routerTab: string;
    className?: string;
    slug: string;
    title: string;
    city?: string;
    primaryButton?: EventCustomFieldLink;
    secondaryButton?: EventCustomFieldLink;
    onSelect?: (selected: string) => void;
    hideTabs?: string[];
    dynamicTabs: typeof Menu;
    isLive?: boolean;
    sponsorFormId?: number;
    accentColor?: string;
};

const EventMainMenu = (props: EventMainMenuProps) => {
    const {
        isLive,
        city,
        routerTab,
        className,
        title,
        primaryButton,
        secondaryButton,
        slug,
        onSelect,
        hideTabs,
        dynamicTabs,
        sponsorFormId,
        accentColor,
    } = props;
    const [selected, setSelected] = useState(routerTab);
    const [modalOpen, setModalOpen] = useState(false);

    // Analytics
    const trackPrimaryMenuButton = useAnalyticsTrigger('Button Clicked', {
        type: 'Event Menu Button 1',
        title: primaryButton?.title,
        slug,
        url: primaryButton?.url || '',
    });
    const trackSecondaryMenuButton = useAnalyticsTrigger('Button Clicked', {
        type: 'Event Menu Button 2',
        title: secondaryButton?.title,
        slug,
        url: secondaryButton?.url || '',
    });

    const menuRef = useRef<HTMLDivElement>(null);
    const scrollIntoMenu = () => menuRef?.current && menuRef.current.scrollIntoView();
    useEffect(() => {
        if (routerTab === 'home') {
            scrollIntoMenu();
        }
    }, [routerTab]);

    // Highlight selected menu item
    const selectedMenu = (selectedItem: string) => {
        setSelected(selectedItem);
        if (onSelect) {
            onSelect(selectedItem);
        }
    };

    return (
        <div
            ref={menuRef}
            className={cn('sticky bg-white z-[19] top-0 left-0 right-0 self-start border-none', className)}
        >
            <div className="p-5 md:p-10 lg:pl-10 xl:pl-20 border-b">
                <div className="flex flex-wrap justify-between items-center flex-grow-0 flex-shrink-0 gap-12">
                    <div className="md:flex flex-auto justify-between items-start gap-5">
                        <div className="flex flex-auto flex-col justify-between items-start relative gap-4 md:gap-6">
                            <div className="flex flex-row gap-3 items-center">
                                <h1 className="font-semibold text-2xl md:text-3xl text-left text-[#000a39]">
                                    {title}
                                    {city && <span className="text-gray-400 font-light"> - {city}</span>}
                                </h1>
                                {isLive && (
                                    <div>
                                        <Link
                                            href={`/event/${slug}/livestreams`}
                                            className="rounded-none bg-white border border-brand text-sm px-1.5 py-0.5"
                                            style={{ borderColor: 'var(--event-accent-color)' }}
                                        >
                                            <span style={{ color: 'var(--event-accent-color)' }}>Livestreams</span>
                                        </Link>
                                    </div>
                                )}
                            </div>

                            <div className="relative w-full h-8">
                                <div className="absolute left-0 right-6 md:w-full overflow-y-auto no-scrollbar flex justify-start items-start gap-6 md:gap-10 whitespace-nowrap">
                                    {[...Menu, ...dynamicTabs]
                                        .filter(m => hideTabs?.findIndex(hide => hide === m.slug) === -1)
                                        .map(item => (
                                            <div
                                                key={item.slug}
                                                className="flex justify-start items-start relative gap-2.5"
                                            >
                                                <Link
                                                    href={`/event/${slug}/${item.slug}`}
                                                    className={cn(
                                                        'text-md md:text-lg text-left capitalize hover:text-gray-600',
                                                        selected === item.slug ? 'text-event-accent' : '',
                                                    )}
                                                    onClick={() => selectedMenu(item.slug)}
                                                >
                                                    {item.title}
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap md:flex-nowrap whitespace-nowrap justify-start items-start gap-5 pt-6 pb-4 md:pt-1">
                            {Boolean(sponsorFormId) && !isNaN(Number(sponsorFormId)) && (
                                <div>
                                    <Modal
                                        onOpen={() => setModalOpen(true)}
                                        onClose={() => setModalOpen(false)}
                                        open={modalOpen}
                                        shadow="lg"
                                        borderRadius="xl"
                                        classNames={{
                                            base: 'z-[9999] bg-white p-3 md:w-[500px] md:max-w-[700px] w-[85vw] sm:max-h-[760px] max-h-[90vh] overflow-y-auto',
                                            shroud: 'fixed bg-black opacity-40 inset-0 z-[9998]',
                                        }}
                                    >
                                        <Modal.Content>
                                            <Modal.Header>
                                                <Modal.Title className={'text-2xl sm:text-4xl'}>
                                                    Become a sponsor
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body style={{ '--event-accent-color': accentColor } as any}>
                                                <BecomeASponsorForm sponsorFormId={Number(sponsorFormId)} />
                                            </Modal.Body>
                                        </Modal.Content>
                                    </Modal>

                                    <div className="text-xl text-center capitalize text-white font-normal inline-block">
                                        <button
                                            className="px-5 py-3 border cursor-pointer hover:bg-event-accent hover:border-white hover:text-white text-event-accent bg-white border-event-accent"
                                            onClick={() => setModalOpen(true)}
                                            aria-label="Sponsor"
                                        >
                                            Sponsor
                                        </button>
                                    </div>
                                </div>
                            )}

                            {secondaryButton && secondaryButton!.url && (
                                <MainMenuButton
                                    url={secondaryButton!.url}
                                    target={secondaryButton.target ?? ''}
                                    title={secondaryButton.title?.toLowerCase() || ''}
                                    onClick={() => trackSecondaryMenuButton.trigger()}
                                />
                            )}

                            {primaryButton?.url && (
                                <MainMenuButton
                                    primary
                                    url={primaryButton!.url}
                                    target={primaryButton.target ?? ''}
                                    title={primaryButton.title?.toLowerCase() ?? ''}
                                    onClick={() => trackPrimaryMenuButton.trigger()}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventMainMenu;
