import * as React from 'react';
import { useRouter } from 'next/router';

import { BeehiivPublicationIds } from '@blockworks/platform/api/beehiiv';

import NewsletterSubscribeInput from '@/components/pages/frontpage/components/newsletter-subscribe-input';
import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

type EventNewsletterGateProps = {
    onSubscribed?: (userData: { email: string }) => void;
    children?: React.ReactNode;
};

const EventNewsletterGate = (props: EventNewsletterGateProps) => {
    const router = useRouter();
    const { onSubscribed, children } = props;

    return (
        <div className="flex flex-col justify-start gap-5 w-70">
            {children}
            <NewsletterSubscribeInput
                className="w-full lg:w-[50%]"
                inputPlaceholder="Enter email address"
                buttonText="Subscribe"
                source="newsletter-page"
                onButtonClick={({ email, captchaToken }) => {
                    return subscribeToNewsletter({
                        email,
                        subscribeTo: [
                            BeehiivPublicationIds.Daily,
                            BeehiivPublicationIds.Weekly,
                            BeehiivPublicationIds.Events,
                            BeehiivPublicationIds.Webinars,
                        ],
                        source: 'event-livestream',
                        sourcePath: router.asPath,
                        captchaToken,
                    }).then(() => {
                        onSubscribed && onSubscribed({ email: email });
                    });
                }}
            />
        </div>
    );
};

export default EventNewsletterGate;
