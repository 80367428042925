import { useReducer } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { MapPinIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';

import { cn } from '@blockworks/ui/utils';

interface RoomSectionProps {
    initiallyOpen?: boolean;
    children?: JSX.Element | React.ReactNode;
    name: string;
    activeStageHref: string | undefined;
}

const StageSection = ({ children, activeStageHref, name, initiallyOpen = false }: RoomSectionProps) => {
    const [isOpen, toggle] = useReducer(state => !state, initiallyOpen);

    return (
        <div className="flex flex-col gap-8 w-full lg:max-w-[400px] lg:w-[400px] p-4 lg:p-8 even:bg-gray-100 ">
            <div className="flex items-center relative">
                <h3 className="flex-auto uppercase text-xxs font-bold tracking-wide">
                    <MapPinIcon className="w-4 h-4 mr-1 inline-block align-text-bottom" />
                    {name}
                </h3>
                {activeStageHref && (
                    <Link
                        href={activeStageHref}
                        className="rounded-none h-fit bg-white border border-event-accent text-sm px-1.5 py-0.5 mr-6"
                    >
                        <span className="text-event-accent"> Watch Livestream</span>
                    </Link>
                )}
                <button
                    onClick={toggle}
                    aria-label={`Toggle ${name} section`}
                    aria-expanded={isOpen}
                    className="lg:hidden p-1 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-event-accent"
                >
                    <ChevronRightIcon className={cn('w-8 h-8 transition-transform', isOpen ? 'rotate-90' : '')} />
                </button>
            </div>
            <div className={cn('flex-col gap-8', isOpen ? 'flex' : 'lg:flex hidden')}>{children}</div>
        </div>
    );
};

export default StageSection;
