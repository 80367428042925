import * as React from 'react';
import { useEffect, useMemo } from 'react';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';

import PageHeader from '@/components/display/headers/page-header';
import DynamicPage from '@/components/pages/event/layout/components/dynamic-page/dynamic-page';
import { parseHotels } from '@/components/pages/event/layout/components/home/components/renders/render-event-content';
import FeaturedHotel from '@/components/pages/event/layout/components/hotel-booking/components/featured-hotel';
import HotelCards, { Hotel } from '@/components/pages/event/layout/components/hotel-booking/components/hotel-cards';
import getHotels from '@/components/pages/event/layout/components/hotel-booking/utils/get-hotels';
import { Event } from '@/types/article-type';
import { HotelsContent } from '@/types/events-custom-fields';
import useSortableData from '@/utils/hooks/use-sortable-data';

type EventTabHotelBookingProps = {
    content: string;
    event: Event;
    hotelsContent: HotelsContent;
};
const EventTabHotelBooking = (props: EventTabHotelBookingProps) => {
    const { hotelsContent, content, event } = props;

    const hotels = useMemo(() => parseHotels(hotelsContent), [hotelsContent]);

    const {
        items: sortedAndFilteredHotels,
        requestSort,
        sortConfig,
    } = useSortableData<Hotel>(hotels?.length > 0 ? hotels : getHotels(event.slug));

    const featuredHotels = useMemo(
        () => sortedAndFilteredHotels.filter((h: Hotel) => h.featured),
        [sortedAndFilteredHotels],
    );
    const hotelDeals = useMemo(
        () => sortedAndFilteredHotels.filter((h: Hotel) => h.deal && !h.featured),
        [sortedAndFilteredHotels],
    );
    const moreHotels = useMemo(
        () => sortedAndFilteredHotels.filter((h: Hotel) => !h.deal && !h.featured),
        [sortedAndFilteredHotels],
    );

    useEffect(() => {
        requestSort('price', '', 'asc');
    }, []);

    return (
        <div className="p-5 md:p-10 xl:px-20 sm:mr-16 mb-12">
            <section className="flex flex-col gap-10">
                <div>
                    <PageHeader
                        title={hotelsContent?.title ?? ''}
                        description={hotelsContent?.description ?? ''}
                        h1ClassName={'text-4xl'}
                    />

                    {featuredHotels.length > 0 && featuredHotels[0] && (
                        <FeaturedHotel hotel={featuredHotels[0]} title="Blockworks' Pick" className="mt-12" />
                    )}

                    <div className="mx-auto max-w-2xl lg:max-w-8xl mt-12">
                        {hotelDeals.length > 0 && (
                            <>
                                <select
                                    name="sort"
                                    className="float-right pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand focus:border-brand sm:text-sm rounded-md"
                                    onChange={e => {
                                        requestSort(e.target.value, '', 'asc');
                                    }}
                                >
                                    <option selected={sortConfig?.key === 'price'} value="price">
                                        Price (lowest first)
                                    </option>
                                    <option selected={sortConfig?.key === 'name'} value="name">
                                        Name
                                    </option>
                                </select>

                                <h2 className="text-xl font-semibold text-gray-900">All Hotel Deals</h2>

                                <HotelCards
                                    hotels={hotelDeals}
                                    className="mt-8 -mx-px grid grid-cols-1 sm:grid-cols-2 border-t border-l border-gray-200 sm:mx-0 lg:grid-cols-3 2xl:grid-cols-4"
                                />
                            </>
                        )}
                    </div>

                    {moreHotels.length > 0 && (
                        <div className="mx-auto max-w-2xl lg:max-w-8xl mt-12">
                            <h2 className="text-xl font-semibold text-gray-900">More Hotels</h2>

                            <HotelCards
                                hotels={moreHotels}
                                className="mt-8 -mx-px grid grid-cols-1 sm:grid-cols-2 border-t border-l border-gray-200 sm:mx-0 lg:grid-cols-3 2xl:grid-cols-4"
                            />
                        </div>
                    )}

                    {hotelsContent?.text_below && (
                        <div className="prose prose-purple max-w-none prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-purple-500 prose-a:font-base prose-a:border-none prose-a:hover:border-none prose-a:underline prose-pre:rounded-xl prose-pre:bg-purple-900 prose-pre:shadow-lg">
                            <RenderSimpleHtml html={hotelsContent?.text_below ?? ''} />
                        </div>
                    )}
                </div>

                {content && <DynamicPage content={content} className="xl:px-2" />}
            </section>
        </div>
    );
};

export default EventTabHotelBooking;
