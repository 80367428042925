import { AgendaSpeaker, formatUnixTimestampToTime } from '@blockworks/platform/api/grip';
import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { cn } from '@blockworks/ui/utils';

interface SessionCardProps {
    onSelectSession: ({
        speakers,
        selectedSpeaker,
        title,
        startsAt,
        endsAt,
        timezoneInput,
        stageName,
    }: {
        speakers: AgendaSpeaker[];
        selectedSpeaker: number | null;
        title: string;
        startsAt: number;
        endsAt: number;
        timezoneInput: string;
        stageName: string;
        sessionId: string;
        streamUrl: string | null;
        recordingUrl: string | null;
    }) => void;
    startsAt: number;
    endsAt: number;
    title: string;
    stageName: string;
    speakers: AgendaSpeaker[];
    timezone: string;
    onSpeakerClick: (speaker: AgendaSpeaker) => void;
    sessionId: string;
    streamUrl: string | null;
    recordingUrl: string | null;
}

const SessionCard = ({
    onSelectSession,
    startsAt,
    endsAt,
    title,
    stageName,
    speakers,
    timezone,
    onSpeakerClick,
    sessionId,
    streamUrl,
    recordingUrl,
}: SessionCardProps) => {
    return (
        <div className="relative">
            <div className={cn('flex flex-col gap-3 border p-5')}>
                <div className="flex flex-col gap-1 relative">
                    <div className="flex justify-between">
                        <div>
                            <div className="text-xs tracking-wide rounded-md font-bold">{stageName}</div>
                            <div className="text-xs tracking-wide text-event-accent">
                                {formatUnixTimestampToTime(startsAt, timezone)} -
                                {formatUnixTimestampToTime(endsAt, timezone)}
                            </div>
                        </div>
                    </div>
                    <button
                        className={cn(
                            'font-bold tracking-wide text-start hover:text-event-accent outline-event-accent',
                            speakers.length === 0 ? 'pointer-events-none' : '',
                        )}
                        onClick={() =>
                            onSelectSession({
                                speakers,
                                selectedSpeaker: null,
                                title,
                                startsAt,
                                endsAt,
                                timezoneInput: timezone,
                                stageName,
                                sessionId,
                                streamUrl,
                                recordingUrl,
                            })
                        }
                    >
                        {title}
                    </button>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="flex-auto relative group h-[32px]">
                        {speakers.map((speaker, idx) => {
                            return (
                                <div
                                    key={speaker.name}
                                    className="relative inline-block w-[24px] group-hover:w-[40px] group-focus-within:w-[40px] transition-all"
                                    style={{
                                        zIndex: `${speakers.length - idx}`,
                                    }}
                                >
                                    <button
                                        aria-describedby={`${speaker.name}`}
                                        className="relative h-[32px] w-[32px] group/speaker inline-block focus-visible:outline-none"
                                        onClick={() => onSpeakerClick(speaker)}
                                    >
                                        <ImageWithFallback
                                            src={speaker.thumbnail || ''}
                                            width={32}
                                            height={32}
                                            alt={speaker.name || ''}
                                            className="group-focus-visible/speaker:outline-event-accent group-focus-visible/speaker:outline group-focus-visible/speaker:outline-2 rounded-full object-cover aspect-1"
                                        />
                                        <div
                                            id={`${speaker.name}`}
                                            className={cn(
                                                'group-focus-visible/speaker:block group-hover/speaker:block mt-2 hidden absolute translate-x-[-50%] left-[16px] p-1 rounded text-xxs bg-event-accent text-white w-max',
                                                `after:content-[''] after:w-[16px] after:h-[16px] after:absolute after:bg-event-accent after:top-0 after:left-[50%] after:rotate-45 after:translate-x-[-50%] after:z-[-1]`,
                                            )}
                                        >
                                            {speaker.name}
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionCard;
