import React from 'react';

type IChevronProps = {
    className?: string;
    style?: 'primary' | 'black';
    width?: number;
    height?: number;
};

const ChevronBackIcon = (props: IChevronProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 4 7" fill="none">
            <path d="M3.705 1.205L3 0.5L0 3.5L3 6.5L3.705 5.795L1.415 3.5L3.705 1.205Z" fill="#5637CD" />
        </svg>
    );
};

export default ChevronBackIcon;
