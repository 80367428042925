import { format } from '@blockworks/platform/libs/date-fns';
import { cn } from '@blockworks/ui/utils';

export interface AgendaTabsProps {
    className?: string;
    options: string[];
    selected: string;
    onSelect: (value: string) => void;
}

const AgendaTabs = ({ className, options, selected, onSelect }: AgendaTabsProps) => {
    return (
        <div className={cn('flex gap-4', className)} role="tablist" aria-label="Agenda dates">
            {options.map(date => {
                const active = `text-event-accent font-bold`;
                const inactive = 'text-gray-500 hover:text-gray-700';

                // Parse the input date string in UTC
                const Day = new Date(`${date}T00:00:00Z`);

                // Ensure formatting respects UTC by reconstructing the date string
                const utcDay = new Date(Day.getUTCFullYear(), Day.getUTCMonth(), Day.getUTCDate());

                const textLg = format(utcDay, 'MMMM dd');
                const textSm = format(utcDay, 'MMM dd');

                return (
                    <button
                        key={date}
                        role="tab"
                        className={cn(
                            'flex whitespace-nowrap py-3 px-0.5 text-xl lg:text-3xl capitalize',
                            selected === date ? active : inactive,
                        )}
                        onClick={() => onSelect(date)}
                    >
                        <span className="lg:inline hidden">{textLg}</span>
                        <span className="lg:hidden">{textSm}</span>
                    </button>
                );
            })}
        </div>
    );
};

export default AgendaTabs;
